<template>
	<v-container class="mt-4 ml-2" fluid>
	  <v-row>
	  	<v-col cols="12">
	  		<v-card class="shadowCard">

	  			<!-- Titulo -->
	  		  <v-card-title primary-title>
	  		    <span class="text-subtitle-1">Ajuste de pagos</span>
	  		    <v-spacer></v-spacer>

            <v-btn 
              class="mr-2" 
              small dark color="green"
              @click="exportExcel( filterAlumnos  , 'AJUSTES_PAGOS')"
              tile
            >
              <v-icon small>mdi-microsoft-excel</v-icon>
            </v-btn>

            <v-btn
              color="primary"
              dark
              class="text-capitalize"
              @click="initialize()"
              small
              tile
            >
              <v-icon left small>mdi-refresh</v-icon>
              Actualizar
            </v-btn>

	  		  </v-card-title>

	  		  <v-card-text>

	  		  	<!-- Filtros -->
	  		  	<v-row justify="end">
	  		  		<!-- Seleccionar el ciclo -->
	  		  		<v-col cols="12" sm="6" md="4" lg="3">
	  		  			<v-autocomplete
	  		  			  label="Ciclo"
	  		  			  id="id"
	  		  			  filled
	  		  			  dense
	  		  			  single-line
	  		  			  hide-details
	  		  			  v-model="ciclo"
	  		  			  :items="ciclos"
	  		  			  item-text="ciclo"
	  		  			  item-value="id_ciclo"
	  		  			  clearable
	  		  			></v-autocomplete>
	  		  		</v-col>
	  		  		<!-- Seleccionar el plantel -->
	  		  		<v-col cols="12" sm="6" md="4" lg="3">
	  		  			<v-autocomplete
	  		  			  label="Plantel"
	  		  			  id="id"
	  		  			  filled
	  		  			  dense
	  		  			  single-line
	  		  			  hide-details
	  		  			  v-model="plantel"
	  		  			  :items="planteles"
	  		  			  item-text="plantel"
	  		  			  item-value="id_plantel"
	  		  			  clearable
	  		  			></v-autocomplete>
	  		  		</v-col>
	  		  		<v-spacer></v-spacer>
	  		  		<v-col cols="12" sm="6" md="4" lg="5">
	  		  			<v-text-field
	  		  			  name="name"
	  		  			  label="Buscar"
	  		  			  id="id"
	  		  			  filled
	  		  			  dense
	  		  			  hide-details
	  		  			  single-line
	  		  			  append-icon="mdi-magnify"
	  		  			  v-model="search"
	  		  			></v-text-field>
	  		  		</v-col>
	  		  	</v-row>

	  		  	<v-row>
	  		  		<v-col cols="12" class="pt-0">
		  		  		<v-radio-group
						      v-model="tipoFiltro"
						      row
						      hide-details
						    >
						      <v-radio
						        label="Inscripciones"
						        :value="1"
						      ></v-radio>
						      <v-radio
						        label="Movimientos"
						        :value="2"
						      ></v-radio>
						    </v-radio-group>
		  		  	</v-col>
		  		  </v-row>

	  		  	<!-- Tabla -->
	  		    <v-row>
	  		    	<v-col cols="12">
							  <v-data-table
							    :headers="filterHeader"
							    :items="filterAlumnos"
							    class="elevation-0"
							    :search="search"
							    :page.sync="page"
			            @page-count="pageCount = $event"
							    :mobile-breakpoint="100"
			            hide-default-footer
			            dense
			            id="grupos"
			            item-class="clase_row"
						    	:expanded.sync="expanded"
							    item-key="id_alumno"
							    :show-expand="tipoFiltro == 1 ? true : false"
							    single-expand
							  >
							    <!-- Valores que ocupan botons, chips, iconos, etc -->
							    <template v-slot:item.activo_sn="{ item }">
							      <v-chip 
							      	small
							      	:color="item.activo_sn == 1 ? 'green': 'red'"
							      	dark
							      >{{ item.activo_sn == 1 ? 'SI': 'NO'  }}</v-chip>
							    </template>

							    <template v-slot:expanded-item="{ headers, item }" v-if="tipoFiltro == 1">
							      <td :colspan="headers.length" class="py-10">
			                <v-card class="elevation-5">
			                  <v-card-title class="text-subtitle-1">
			                    Movimientos: <b> {{ item.alumno }}</b>
			                  </v-card-title>
			                  <v-card-text>
			                    <v-data-table
			                      :headers="headersDos"
			                      :items="item.movimientos"
			                      class="elevation-0 mb-5"
			                      dense
			                      hide-default-footer
			                    > 
			                    	<template v-slot:item.actions="{ item }">
												    	<v-btn 
												    		color="primary" 
												    		x-small
												    		@click="editItem(item)"
												    		class="mr-2"
												    	>
													      <v-icon small>mdi-magnify</v-icon>
												    	</v-btn>

												    	<v-btn 
												    		color="red" 
												    		x-small
												    		dark
												    		@click="deleteItem(item)"
												    		class="mr-2"
												    	>
													      <v-icon small>mdi-close</v-icon>
												    	</v-btn>
												    </template>
			                    </v-data-table>
			                  </v-card-text>
			                </v-card>
			              </td>
							    </template>

							    <template v-slot:item.actions="{ item }">
							    	<v-btn 
							    		color="primary" 
							    		x-small
							    		@click="editItem(item)"
							    		class="mr-2"
							    	>
								      <v-icon small>mdi-magnify</v-icon>
							    	</v-btn>

							    	<v-btn 
							    		color="red" 
							    		x-small
							    		dark
							    		@click="bajaAlumno(item)"
							    		class="mr-2"
							    	>
								      <v-icon small>mdi-close</v-icon>
							    	</v-btn>
							    </template>

							    <template v-slot:no-data>
							      <v-btn
							        color="primary"
							        @click="initialize"
							        small
							      >
							        Actualizar
							      </v-btn>
							    </template>

							  </v-data-table>
	  		    	</v-col>
	  		    </v-row>
	  		  </v-card-text>
	  		  <v-card-actions>
            <v-spacer></v-spacer>
            <v-pagination
              circle
              v-model="page"
              :length="pageCount"
              :total-visible="7"
            ></v-pagination>
          </v-card-actions>
	  		</v-card>
	  	</v-col>
	  </v-row>

	  <!-- Dialogo de editar o agregar-->
	  <v-dialog
      v-model="dialog"
      max-width="1200px"
      persistent
    >
      <v-card>
        <v-card-title>
          <span class="text-subtitle-1"><b>Alumno: {{ editedItem.alumno }} </b></span>
          <v-spacer></v-spacer>
          <span class="text-subtitle-1">
          	<strong>ID: {{ editedItem.id_alumno }}</strong>
          </span>
        </v-card-title>
        <v-tabs vertical v-model="tabs">
        	<v-tab class="text-capitalize" v-for="(tab, i) in menu" :key="i">
		        <v-icon left v-text="tab.icono"></v-icon>
		        {{ tab.text }}
		      </v-tab>

		      <!-- información del grupo -->
		      <v-tab-item>
		        <v-card flat>
		          <v-card-text class="pt-0">
		          	<InfoAlumno  v-if="tabs == 0 && dialog" :editedItem="editedItem" @click="initialize()"/> 
			        </v-card-text>
		        </v-card>
		      </v-tab-item>

		      <!-- Tutro -->
		      <v-tab-item>
		        <v-card flat>
		          <v-card-text>
		            <Tutor v-if="tabs == 1 && dialog" :editedItem="editedItem"/>
		          </v-card-text>
		        </v-card>
		      </v-tab-item>

		      <!-- Grupos -->
		      <v-tab-item>
		        <v-card flat>
		          <v-card-text>
		            <GruposAlumno  v-if="tabs == 2 && dialog" :editedItem="editedItem"/>
		          </v-card-text>
		        </v-card>
		      </v-tab-item>

		      <!-- Becas -->
		      <v-tab-item>
		        <v-card flat>
		          <v-card-text>
		            <BecaAlumno  v-if="tabs == 3 && dialog" :editedItem="editedItem"/>
		          </v-card-text>
		        </v-card>
		      </v-tab-item>
		    </v-tabs>

        
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="close"
          >
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="text-h6">¿Confirmas que deseas eliminar defiminitvamente este movimiento?</v-card-title>
        <v-card-actions>
          <v-btn 
          	color="red" 
          	dark
          	small
          	rounded
          	@click="closeDelete"
          >Cancelar</v-btn>
          <v-spacer></v-spacer>
          <v-btn 
          	color="primary" 
          	dark
          	small
          	rounded
          	@click="deleteItemConfirm"
          >Confirmar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogBajaAlumno" max-width="500px">
      <v-card>
        <v-card-title class="text-h6">¿Confirmas que deseas dar de baja al alumno del grupo?</v-card-title>
        <v-card-actions>
          <v-btn 
          	color="red" 
          	dark
          	small
          	rounded
          	@click="closeDelete"
          >Cancelar</v-btn>
          <v-spacer></v-spacer>
          <v-btn 
          	color="primary" 
          	dark
          	small
          	rounded
          	@click="bajaConfirm"
          >Confirmar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"          :parametros="parametros"/>
    <carga                v-if="cargar"/>
    <DialogInscripcion    v-if="inscribir.estatus == true"          :inscribir="inscribir"/>


	</v-container>
</template>
<script>
import axios from 'axios';
	import { mapGetters } from 'vuex'
	// Componentes
  import Alerta             from '@/components/alertas/Alerta.vue'
  import carga              from '@/components/alertas/carga.vue';
  import InfoAlumno         from '@/components/inscripciones/InfoAlumno.vue';
  import GruposAlumno       from '@/components/inscripciones/GruposAlumno.vue';
  import Tutor              from '@/components/inscripciones/Tutor.vue';
  import BecaAlumno         from '@/components/inscripciones/BecaAlumno.vue';
  import DialogInscripcion  from '@/components/inscripciones/DialogInscripcion.vue';

  import validarErrores  from '@/mixins/validarErrores'
  import funcionesExcel        from '@/mixins/funcionesExcel'

  export default {

    mixins: [ validarErrores, funcionesExcel ],

  	components:{
      Alerta,
      carga,
      InfoAlumno,
      GruposAlumno,
      Tutor,
      BecaAlumno,
      DialogInscripcion
    },

    data: () => ({
    	tabs: 0, 
    	// Alertas
    	parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      dialog: false,
      dialogDelete: false,
      dialogBajaAlumno:false,
      editedIndex: -1,

      editedItem: {
        id_grupo:0,
				forma_pago:'',
				comentarios:'',
				fecha_alta:null,
				activo_sn:0,
				fecha_baja:null,
				id_usuario_ultimo_cambio:0,
				fecha_ultimo_cambio:null,
      },

      defaultItem: {
        id_grupo:0,
				forma_pago:'',
				comentarios:'',
				fecha_alta:null,
				activo_sn:0,
				fecha_baja:null,
				id_usuario_ultimo_cambio:0,
				fecha_ultimo_cambio:null,
      },

      // Filtros: 
      incio:null,
			fin:null,
			plantel: null,
			ciclo: null,

      // tabla
			search:'',
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      grupos: [],
      alumnos:[],
      headers: [
        { text: 'ID'                      , value: 'id_alumno'            },
        { text: 'Matricula'               , value: 'matricula'            },
        { text: 'Alumno'                  , value: 'alumno'               },
        // { text: 'Celular'                 , value: 'celular'              },
        { text: 'Grupo'                   , value: 'grupo'                },
        { text: 'Precio grupo'            , value: 'precio'               },
        { text: 'Precio con descuento'    , value: 'precio_grupo_con_descuento_x_alumno' },
        { text: 'Monto pagado'            , value: 'pagado'               },
        { text: 'Monto adeudo'            , value: 'adeudo'               },
        { text: 'Saldo favor'             , value: 'saldofavor'           },
        { text: 'Alta'                    , value: 'fecha_alta'           },
        { text: 'Activo'                  , value: 'activo_sn'            },
        { text: 'Actions'                 , value: 'actions'              },
      ],

      expanded: [],

      headersDos: [
        { text: 'id_ingreso'    , value: 'id_ingreso'      },
        { text: 'Alumno'        , value: 'alumno'          },
        { text: 'Grupo'         , value: 'grupo'           },
        { text: 'Plantel'       , value: 'plantel'         },
        { text: 'Monto pagado'  , value: 'monto_pagado'    },
        { text: 'Fecha'         , value: 'fecha_pago'      },
        { text: 'Forma pago'    , value: 'forma_pago'      },
        { text: 'Registró'      , value: 'nombre_completo' },
        { text: 'Actions'       , value: 'actions'         },
      ],

      ciclos:[],
			planteles:[],
			inscribirAlumno: false,
			inscribir: { estatus: false },
			menu:[{text:'Alumno',icono:'mdi-account'},{text:'Tutor',icono:'mdi-account'},{text:'Pagos',icono:'mdi-bitcoin'},{text:'Beca',icono:'mdi-percent-outline'}],
			inscripciones:[],
			movimientos:[],
			tipoFiltro: 1
    }),

    computed: {
    	...mapGetters('login',['getdatosUsuario']),

    	filterHeader( ){
    		if( this.tipoFiltro == 1 ){ return this.headers }
    			return this.headersDos
    	},

      formTitle () {
        return this.editedIndex === -1 ? 'Agregar Grupo' : 'Editar Grupo'
      },

      filterAlumnos( ){

      	const alumnos = this.tipoFiltro == 1 ? this.inscripciones : this.movimientos

      	if( this.plantel ){
      		return alumnos.filter( el=> { return el.id_plantel == this.plantel })
      	}
      	
      	return alumnos
      }
    },

    watch: {
      dialog (val) { val || this.close() },

      dialogDelete (val) { val || this.closeDelete() },

      dialogBajaAlumno (val) { val || this.closeDelete() },

      tabs( val ) { console.log( val ) },

      ciclo( val ) { if( val ){ this.initialize() } }

    },

    async created () {
      // await this.initialize()
      await this.getCiclos()
      await this.getPlanteles()
    },

    methods: {
      initialize () {
      	if( !this.ciclo ){ return this.validarErrorDirecto('Favor de seleccionar un ciclo para poder continuar') }
      	this.cargar        = true
        this.alumnos       = []
        this.inscripciones = []
				this.movimientos   = []
        
        return this.$http.get('ingresos.ciclo/' + this.ciclo).then(response=>{
        	this.inscripciones = response.data.inscripcionesAlumnos
        	this.movimientos   = response.data.movimientos
        	this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      deleteItem (item) {
        this.editedIndex = this.filterAlumnos.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      bajaAlumno(item) {
        this.editedIndex = this.filterAlumnos.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogBajaAlumno = true
      },

      bajaConfirm () {
        this.cargar = true
        return this.$http.delete('ingreso.eliminar/' + this.editedItem.id_ingreso ).then(response=>{
        	this.cargar      = false
        	this.closeDelete()
        	this.initialize()
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      deleteItemConfirm () {
        this.cargar = true
        return this.$http.delete('ingreso.eliminar/' + this.editedItem.id_ingreso ).then(response=>{
        	this.cargar      = false
        	this.closeDelete()
        	this.initialize()
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      closeDelete () {
        this.dialogDelete     = false
        this.dialogBajaAlumno = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      getCiclos () {
      	this.cargar = true
        this.ciclos = []
        return this.$http.get('ciclos.activos.erp').then(response=>{
        	this.ciclos = response.data
        	this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getPlanteles () {
      	this.cargar = true
        this.planteles = []
        return this.$http.get('planteles.activos').then(response=>{
        	this.planteles = response.data
        	this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      editItem (item) {
        this.editedIndex = this.grupos.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },
    },
  }
</script>


<style>
  #grupos td:nth-child(1) {
    background-color: #E8E8E8 !important;
  }

  #grupos td{
    font-size: 12px !important;
    padding-left: 5px;
    padding-right: 5px;
  }

  .adeudo_alumno{
  	background-color: #FF3030 !important;
  	color: white;
  }

</style>